import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowToLeft,
  // faArrowToRight,
  faInfo,
  faChevronDown,
  faChevronUp,
  faArrowToTop,
  faArrowToBottom,
  faAngleDoubleDown,
} from '@fortawesome/pro-solid-svg-icons';
import * as d3 from 'd3';
import './Sidebar.scss';


class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionExpanded: false,
      sidebarOpen: true,
    };
    this.loggedProps = {
      currentEvent: null,
    };
    this.currentEventRef = React.createRef();
    this.containerRef = React.createRef();
  }

  componentDidUpdate() {
    const newEvent = this.isNewEvent();

    if (newEvent) {
      this.scrollToCurrent();
    }
  }

  getMobileExpandButton() {
    const {
      mobile,
      setMobileSidebarView,
      mobileSidebarView,
      dropdownOpen,
    } = this.props;

    const getNewToggle = () => {
      if (mobileSidebarView === 'collapsed') return 'expanded';
      if (mobileSidebarView === 'hidden') return 'collapsed';
      return 'collapsed';
    };

    const getIcon = () => {
      if (mobileSidebarView === 'collapsed') return faArrowToTop;
      if (mobileSidebarView === 'expanded') return faAngleDoubleDown;
      return faInfo;
    };

    const onClick = () => {
      setMobileSidebarView(getNewToggle());
    };
    if (!mobile || (mobile && dropdownOpen)) return null;

    return (
      <div
        className="sidebar__mobile-toggle"
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={getIcon()}
        />
      </div>
    );
  }

  getMobileHideButton() {
    const {
      mobile,
      setMobileSidebarView,
    } = this.props;

    if (!mobile) return null;

    const onClick = () => {
      setMobileSidebarView('hidden');
    };

    return (
      <div
        className="sidebar__mobile-hide"
        onClick={onClick}
      >
        Hide
        <FontAwesomeIcon
          icon={faArrowToBottom}
        />
      </div>
    );
  }

  getEventsList() {
    const {
      currentMap,
      setCurrentEvent,
      currentEvent,
    } = this.props;

    const isCurrentEvent = event => currentEvent !== null && currentEvent.id === event.id;

    const getRowClass = (event) => {
      let className = 'sidebar__event-row';
      if (isCurrentEvent(event)) {
        className = `${className} ${className}--selected`;
      }

      return className;
    };

    const getCurrentEventRef = (event) => {
      if (!isCurrentEvent(event)) return false;
      return this.currentEventRef;
    };

    return currentMap.events
      .map(d => (
        <div
          className="sidebar__event-row-outer"
          key={d.id}
          name={d.id}
        >
          <div
            className={getRowClass(d)}
            onClick={() => {
              const eventWithSource = Object.assign(
                {},
                d,
                { eventSource: 'sidebar' },
              );
              setCurrentEvent(eventWithSource);
            }}
            ref={getCurrentEventRef(d)}
          >
            <div className="sidebar__event-date">
              {d.displayDate}
            </div>
            <div className="sidebar__event-name" dangerouslySetInnerHTML={{ __html: d.name }} />
            <div className="sidebar__event-description" dangerouslySetInnerHTML={{ __html: d.description }} />
          </div>
          <hr className="sidebar__event-break" />
        </div>
      ));
  }

  getSingleEvent() {
    const { currentMap } = this.props;
    return <div>{currentMap.title}</div>;
  }

  getEvents() {
    const { currentMap } = this.props;
    if (currentMap === null) return <div />;
    if (currentMap.events !== null) {
      return this.getEventsList();
    }
    return this.getSingleEvent();
  }

  isNewEvent() {
    const { currentEvent } = this.props;
    const loggedCurrent = this.loggedProps.currentEvent;
    // console.log('currentEvnt', currentEvent);
    if (currentEvent === null) return false;
    if (loggedCurrent === null) return true;
    return (loggedCurrent !== currentEvent.id);
  }

  scrollToCurrent() {
    if (this.currentEventRef.current === null) return;

    const containerPos = this.containerRef.current.getBoundingClientRect().top;
    const eventPos = this.currentEventRef.current.getBoundingClientRect().top - containerPos;
    const currentScroll = this.containerRef.current.scrollTop;

    d3.transition()
      .duration(500)
      .tween('eventScroll', this.scrollTween.call(this, eventPos + currentScroll));
  }

  scrollTween(newScrollPos) {
    return () => {
      const i = d3.interpolateNumber(this.containerRef.current.scrollTop, newScrollPos);
      return (t) => {
        this.containerRef.current.scrollTop = i(t);
      };
    };
  }

  render() {
    const {
      currentMap,
      sidebarOpen,
      toggleSidebar,
      // getResponsiveClass,
      mobile,
      mobileSidebarView,
    } = this.props;

    const {
      descriptionExpanded,
    } = this.state;

    const getTitleBlockClass = () => {
      let className = 'sidebar__map-row';
      if (descriptionExpanded) {
        className += ' sidebar__map-row--expanded';
      }

      return className;
    };

    const onExpandTextClick = () => {
      this.setState({
        descriptionExpanded: true,
      });
    };

    const onCollapseTextClick = () => {
      this.setState({
        descriptionExpanded: false,
      });
    };

    const getToggleIcon = () => (sidebarOpen
      ? faArrowToLeft
      : faInfo);

    const getSidebarClass = () => {
      let base = 'sidebar__outer';
      if (!sidebarOpen) {
        base += ' sidebar__outer--collapsed';
      }
      if (mobile) {
        base += ' sidebar__outer--mobile';
        base += ` sidebar__outer--mobile-${mobileSidebarView}`;
      }
      return base;
    };
    return (
      <div className={getSidebarClass()}>
        {this.getMobileHideButton()}
        <div className="sidebar__map-title">
          <div className="sidebar__map-title-inner">
            {currentMap.title}
          </div>
        </div>
        <div className="sidebar" ref={this.containerRef}>
          <div className="sidebar__inner">
            <div className={getTitleBlockClass()}>
              <div
                className="sidebar__map-description"
                dangerouslySetInnerHTML={{ __html: currentMap.description }}
              />
              <div className="sidebar__gradient" onClick={onExpandTextClick} />
              <div className="sidebar__read-more">
                Read more
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
              <div className="sidebar__read-less" onClick={onCollapseTextClick}>
                Read less
                <FontAwesomeIcon icon={faChevronUp} />
              </div>
            </div>
            <div className="sidebar__events-block-title">EVENTS</div>
            <div className="sidebar__event-row">
              <hr className="sidebar__event-break" />
            </div>
            {this.getEvents()}
          </div>
        </div>
        <div
          className="sidebar__toggle-button"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon icon={getToggleIcon()} />
        </div>
        {this.getMobileExpandButton()}
      </div>
    );
  }
}

Sidebar.defaultProps = {
  currentMap: null,
  // currentEvent: null,
  mobile: false,
  mobileSidebarView: null,
  dropdownOpen: false,
  setMobileSidebarView: null,
  currentEvent: null,
};

Sidebar.propTypes = {
  currentMap: PropTypes.shape({
    actors: PropTypes.array,
    description: PropTypes.string,
    id: PropTypes.string,
    map: PropTypes.number,
    mapCaption: PropTypes.string,
    title: PropTypes.string,
    yearEnd: PropTypes.number,
    yearStart: PropTypes.number,
  }),
  currentEvent: PropTypes.shape({
    actors: PropTypes.array,
    citation: PropTypes.string,
    date: PropTypes.object,
    displayDate: PropTypes.string,
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  setCurrentEvent: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  mobile: PropTypes.bool,
  mobileSidebarView: PropTypes.string,
  setMobileSidebarView: PropTypes.func,
  dropdownOpen: PropTypes.bool,
};

export default Sidebar;
