import React from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMap,
} from '@fortawesome/pro-solid-svg-icons';
import './MapsDropdown.scss';

const dropdownRoot = document.getElementById('dropdown-root');

const getRowClass = ({
  row,
  currentMap,
}) => {
  let rowClass = 'maps-dropdown__row';

  if (row.id === currentMap.id) {
    rowClass += ' maps-dropdown__row--selected';
  }

  return rowClass;
};

class MapsDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.dropContainer = document.createElement('div');
  }

  componentDidMount() {
    dropdownRoot.appendChild(this.dropContainer);
  }

  componentWillUnmount() {
    dropdownRoot.removeChild(this.dropContainer);
  }

  getRows() {
    const {
      maps,
      currentMap,
      setCurrentMap,
    } = this.props;

    return maps.map((d) => {
      const era = ` ${d.yearStart} - ${d.yearEnd}`;
      const rowClass = getRowClass({
        row: d,
        currentMap,
      });
      return (
        <div
          className={rowClass}
          key={d.id}
          onClick={() => setCurrentMap(d)}
        >
          <div className="maps-dropdown__row-icon">
            <FontAwesomeIcon icon={faMap} />
          </div>
          <div className="maps-dropdown__row-text">
            <div className="maps-dropdown__row-era">
              {era}
            </div>
            {d.title}
          </div>
        </div>
      );
    });
  }

  getDropdownContent() {
    const {
      buttonNode,
      mouseOver,
      mouseOut,
      mobile,
    } = this.props;
    let pos = null;
    if (!mobile) {
      const {
        x,
        y,
        height,
      } = buttonNode.current.getBoundingClientRect();
      pos = {
        left: `${x}px`,
        top: `${y + height + 30}px`,
      };
    }

    let containerClass = 'maps-dropdown';
    if (mobile) {
      containerClass += ` ${containerClass}--mobile`;
    }
    
    return (
      <div
        className={containerClass}
        style={pos}
        onMouseOver={mouseOver}
        onMouseOut={mouseOut}
        onFocus={mouseOver}
        onBlur={mouseOut}
      >
        {this.getRows()}
      </div>
    );
  }


  render() {
    return ReactDom.createPortal(
      this.getDropdownContent(),
      this.dropContainer,
    );
  }
}

MapsDropdown.defaultProps = {
  maps: null,
  buttonNode: null,
  currentMap: null,
};

MapsDropdown.propTypes = {
  maps: PropTypes.arrayOf(PropTypes.object),
  buttonNode: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  currentMap: PropTypes.shape({
    actors: PropTypes.array,
    description: PropTypes.string,
    id: PropTypes.string,
    map: PropTypes.number,
    mapCaption: PropTypes.string,
    title: PropTypes.string,
    yearEnd: PropTypes.number,
    yearStart: PropTypes.number,
  }),
  setCurrentMap: PropTypes.func.isRequired,
  mouseOver: PropTypes.func.isRequired,
  mouseOut: PropTypes.func.isRequired,
  mobile: PropTypes.bool.isRequired,
};

export default MapsDropdown;
