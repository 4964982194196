import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faFireAlt,
  faMapMarker,
  faSwords,
  faHandshakeAlt,
  faRunning,
} from '@fortawesome/pro-solid-svg-icons';
import './Legend.scss';

const allEventTypes = [
  'Settlement',
  'Colonial Settlement',
  'Battle',
  'Revolt',
  'Other',
  'Changing Alliance',
  'Evacuation',
];

const eventIcons = {
  Settlement: faUsers,
  'Colonial Settlement': faUsers,
  Battle: faSwords,
  Revolt: faFireAlt,
  Other: faMapMarker,
  Evacuation: faRunning,
  'Changing Alliance': faHandshakeAlt,
};

class Legend extends React.PureComponent {
  getEvents() {
    const { eventTypes } = this.props;
    return allEventTypes.filter(d => eventTypes.includes(d));
  }

  getEventLegendItems() {
    const events = this.getEvents();
    if (events.length === 0) return null;
    const eventItems = events.map((d) => {
      const eventItem = (
        <div className="legend__event" key={d}>
          <FontAwesomeIcon className="legend__icon" icon={eventIcons[d]} />
          {d}
        </div>
      );
      return eventItem;
    });
    // return (
    //   <div className="legend__events">
    //     <div className="legend__label">EVENT TYPES:</div>
    //     {eventItems}
    //   </div>
    // );
    return [
      <div className="legend__label legend__events-label" key="event-types-label">EVENT TYPES:</div>,
      ...eventItems,
    ];
  }

  getActors() {
    const { actors } = this.props;

    if (!actors || actors.length === 0) return null;

    const actorsSorted = actors.map(d => d.name)
      .sort()
      .map(d => actors.find(dd => dd.name === d));

    const actorItems = actorsSorted.map((d) => {
      const swatchStyle = {
        background: !d.color ? 'darkgrey' : d.color.toLowerCase(),
      };
      const actorItem = (
        <div className="legend__actor" key={d.id}>
          <div style={swatchStyle} className="legend__swatch" />
          <div className="legend__actor-name">{d.name}</div>
        </div>
      );
      return actorItem;
    });
    // return (
    //   <div className="legend__actors">
    //     <div className="legend__label">ACTORS:</div>
    //     {actorItems}
    //   </div>
    // );
    return [
      <div className="legend__label legend__actors-label" key="actors-label">ACTORS:</div>,
      ...actorItems,
    ];
  }

  render() {
    const { mobile } = this.props;
    let className= 'legend';
    const events = this.getEvents();
    if (mobile) {
      className += ` ${className}--mobile`;
    }
    if (events.length === 0) {
      className += ` ${className}--no-events`;
    }
    return (
      <div className={className}>
        {this.getEventLegendItems()}
        {this.getActors()}
      </div>
    );
  }
}

Legend.defaultProps = {
  actors: null,
};

Legend.propTypes = {
  eventTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  actors: PropTypes.arrayOf(PropTypes.object),
};

export default Legend;
