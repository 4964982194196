import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowRight,
} from '@fortawesome/pro-solid-svg-icons';
import './About.scss';
import tang from './tang.jpg';

const aboutRoot = document.getElementById('about-root');

class About extends React.Component {
  constructor(props) {
    super(props);
    this.aboutContainer = document.createElement('div');
  }

  componentDidMount() {
    aboutRoot.appendChild(this.aboutContainer);
  }

  componentWillUnmount() {
    aboutRoot.removeChild(this.aboutContainer);
  }

  render() {
    const { close, mobile } = this.props;
    let className = 'about';
    if (mobile) {
      className += ` ${className}--mobile`;
    }
    const content = (
      <div className={className}>
        <div className="about__inner">
          <div className="about__header">
            HAITIAN REVOLUTION
          </div>
          <div className="about__content">
            <div className="about__image">
              <div className="about__go-to-row">
                <div
                  className="about__go-to"
                  onClick={close}
                >
                  <div className="about__go-to-icon">
                    <FontAwesomeIcon
                      icon={faArrowRight}
                    />
                  </div>
                  <div
                    className="about__go-to-text"
                    onClick={close}
                  >
                    Go to Map
                  </div>
                </div>
              </div>
            </div>
            <div className="about__narrative">
              <p>Stephanie Curci and Chris Jones are instructors in English and History, respectively, at <a href="http://www.andover.edu/">Phillips Academy Andover</a>, where they also teach an interdisciplinary course entitled The History and Literature of the Haitian Revolution. Additionally, Stephanie Curci created and maintains <a href="http://www.mappinghaitianhistory.com/">Mapping Haitian History,</a> an online visual records site for Haitian historical and cultural sites.</p>
              <p>We designed this site with a few different audiences in mind. Primarily, its purpose is to help our students understand the complicated narrative of the Haitian Revolution across time and space. These students include high school seniors as well as tenth-grade students, who study a shorter unit on the Haitian Revolution. We also wanted to provide a resource for other teachers at the high-school level who might be looking for a collection of English-language materials to help create or buttress an existing unit on the Haitian Revolution. In addition to the works cited below, we are sharing our <a href="https://docs.google.com/document/d/1PxuZS03OPLo3v7HxF36XvA8KpJ7zgN99IXeYea8Fj68/edit?usp%3Dsharing">10-day 10th-grade unit</a> and <a href="https://docs.google.com/document/d/1y-V6txQLs0sRM4wB8kVyEpUz3-SJssVQvMN4FiQDFw0/edit?usp%3Dsharing">ten-week 12th-grade course</a>, each of which have a variety of assignments and prompts. A description of each map (designed for teachers) can also be found <a href="https://docs.google.com/document/d/1Y7OF7RDBRXtwG4KkT1ZfmBkZb8tRAEgYbrcP_q7P9pA/edit?usp=sharing">here</a>.</p>
              <p>Our students are multilingual and multinational, but we are an English-language, American school. For the moment, this is an English-language site and in creating this site, we read widely but ultimately tied each map point to an English-language source so that teachers could find resources in that language (see below). We acknowledge the problems of power in such an approach. If it becomes useful, we would hope to make the site available in other languages. We have added <a href="https://docs.google.com/document/d/1QtVhJp1lpfuGFdaDqI3YlwVdiY-5c88leqJMtN7X7fE/edit?usp%3Dsharing">multilingual resources here.</a> We welcome feedback and questions at <a href="mailto:scurci@andover.edu">scurci@andover.edu</a>.</p>
              <div className="about__citations">
                <p>Anville, Jean-Baptiste Bourguignon d'. "L'Isle Espagnole sous le nom indien d'Hayti, ou comme elle &eacute;toit poss&eacute;d&eacute;e par ses habitans naturels lors de sa d&eacute;couverte, avec les premiers &eacute;tablissemens espagnols / par le Sr. d'Anville ; Leparmentier sculpsit." Map. 1731. <a target="_blank" href="https://gallica.bnf.fr/ark:/12148/btv1b84693810/f1.item.r=carte%20de%20l'isle%20d'hayti.zoom">https://gallica.bnf.fr/ark:/12148/btv1b84693810/f1.item.r=carte%20de%20l'isle%20d'hayti.zoom.</a></p>
                <p>de Griwtonn, P.L. Carte de l'Isle de St. Domingue. Map. Paris, 1760: chez Lattr&eacute; Graveur ordinaire du Roi rue St. Jacques, No. 20., 1760. <a target="_blank" href="https://jcb.lunaimaging.com/luna/servlet/detail/JCBMAPS~1~1~1755~103930002:Carte-de-l-Isle-de-St--Domingue-?qvq=q:Griwtonn;lc:JCB~1~1,JCBBOOKS~1~1,JCBMAPS~1~1,JCBMAPS~2~2,JCBMAPS~3~3&mi=1&trs=2;https://www.geographicus.com/P/AntiqueMap/SantoDomingo-defer-1723">https://jcb.lunaimaging.com/luna/servlet/detail/JCBMAPS~1~1~1755~103930002:Carte-de-l-Isle-de-St--Domingue-?qvq=q:Griwtonn;lc:JCB~1~1,JCBBOOKS~1~1,JCBMAPS~1~1,JCBMAPS~2~2,JCBMAPS~3~3&amp;mi=1&amp;trs=2;https://www.geographicus.com/P/AntiqueMap/SantoDomingo-defer-1723.</a></p>
                <p>Dubois, Laurent. Avengers of the New World: The Story of the Haitian Revolution. Cambridge, MA: Belknap Press, 2005.</p>
                <p>Dubois, Laurent, and John D. Garrigus. Slave Revolution in the Caribbean, 1789-1804: A Brief History with Documents. 2nd ed. Boston: Bedford/St. Martin's, Macmillan Learning, 2017.</p>
                <p>Fick, Carolyn E. The Making of Haiti: The Saint Domingue Revolution from below. Knoxville, Tenn.: Univ. of Tennessee Press, 2004.</p>
                <p>Florida Museum of Natural History at the University of Florida. "Historical Archeology: Archeology in Haiti." Florida Museum of Natural History at the University of Florida. Accessed August 23, 2019. <a target="_blank" href="https://www.floridamuseum.ufl.edu/histarch/research/haiti/">https://www.floridamuseum.ufl.edu/histarch/research/haiti/.</a></p>
                <p>&mdash;&mdash;&mdash;. "Historical Archeology: Dominican Republic." Florida Museum of Natural History at the University of Florida. Accessed August 23, 2019. <a target="_blank" href="https://www.floridamuseum.ufl.edu/histarch/research/dominican-republic/">https://www.floridamuseum.ufl.edu/histarch/research/dominican-republic/.</a></p>
                <p>Poirson, Jean Baptiste, and Antoine-Fran&ccedil;ois Tardieu. Carte de St Domingue par</p>
                <p> J.B. Poirson en vend&eacute;miaire An XII (1803). Grav&eacute; par Tardieu l'a&icirc;n&eacute;.</p>
                <p> Map. Paris, France, 1803. <a target="_blank" href="http://catalogue.bnf.fr/ark:/12148/cb40593927f">http://catalogue.bnf.fr/ark:/12148/cb40593927f.</a></p>
                <p>Popkin, Jeremy D. A Concise History of the Haitian Revolution. Malden, MA: Wiley-Blackwell, 2012.</p>
                <p>Schimmer, Russell. "Hispaniola." Genocide Studies Program. Accessed August 23, 2019. <a target="_blank" href="https://gsp.yale.edu/case-studies/colonial-genocides-project/hispaniola">https://gsp.yale.edu/case-studies/colonial-genocides-project/hispaniola.</a></p>
                <p>This project was developed by Stephanie Curci and Chris Jones with support from the Tang Institute and was built with Axis Maps. We are deeply grateful to both the Tang Institute and Axis Maps for their support of this digital project.</p>
              </div>
              <img src={tang} alt="Tang Institute" />
            </div>
          </div>
        </div>
        <div className="about__close-button">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={close}
          />
        </div>
      </div>
    );

    return ReactDOM.createPortal(
      content,
      this.aboutContainer,
    );
  }
}

About.propTypes = {
  close: PropTypes.func.isRequired,
  mobile: PropTypes.bool.isRequired,
};

export default About;
