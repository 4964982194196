import React from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

class TimelineChart extends React.PureComponent {
  getEvents() {
    const {
      currentMap,
      setCurrentEvent,
      currentEvent,
      setHighlightEvent,
      setTooltip,
      width,
      removeTooltip,
      highlightEvent,
      padding,
      labelsLeft,
    } = this.props;

    if (currentMap === null || width === null) return <div />;
    const { events } = currentMap;
    if (events === null) return <div />;
    const timeExtent = d3.extent(events, d => d.date);

    const eventScale = d3.scaleTime()
      .domain(timeExtent)
      .range([padding.left, width - padding.right]);
    const eventPositions = new Map();
    events.forEach((d) => {
      if (!eventPositions.has(d.id)) {
        eventPositions.set(d.id, eventScale(d.date));
      }
    });

    // const svgHeight = 75;

    const tickValues = eventScale.ticks(10);
    const tickHeight = 10;
    const dotRowTop = 16 + (tickHeight / 2);
    const ticks = tickValues.map((d) => {
      const x = eventScale(d);
      const translate = `translate(${Math.round(x)} ${dotRowTop})`;
      return (
        <g
          key={d}
          transform={translate}
        >
          <line
            x1="0"
            x2="0"
            y1="0"
            y2={tickHeight}
            className="timeline__tick"
          />
          <text
            textAnchor="middle"
            x="0"
            y={dotRowTop * 2}
          >
            {d.getFullYear()}
          </text>
        </g>
      );
    });

    const isHighlightEvent = event => highlightEvent !== null && highlightEvent.id === event.id;

    const isCurrentEvent = event => currentEvent !== null && currentEvent.id === event.id;

    const getCircleClass = (d) => {
      let className = 'timeline__event';
      if (isCurrentEvent(d)) {
        className += ' timeline__event--selected';
      }
      if (isHighlightEvent(d)) {
        className += ' timeline__event--highlighted';
      }
      return className;
    };

    const getCircleRadius = (d) => {
      if (isCurrentEvent(d)) return 8;
      return 5;
    };

    // move left to right, spacing out events as needed
    let currentLeft = -1;
    const leftSpacedEvents = events.map((d) => {
      const copy = Object.assign({}, d);
      currentLeft = Math.min(eventScale.range()[1],
        Math.max(currentLeft + 10, eventPositions.get(d.id)));
        
      copy.x = currentLeft;
      return copy;
    });

    // repeat from right to left
    let currentRight = Infinity;
    const rightSpacedEvents = leftSpacedEvents.reverse().map((d) => {
      const copy = Object.assign({}, d);
      currentRight = Math.max(eventScale.range()[0],
        Math.min(currentRight - 10, copy.x));
      copy.x = currentRight;
      return copy;
    });

    const mouseOver = (d) => {
      setHighlightEvent(d);
    };

    const mouseOut = () => {
      setHighlightEvent(null);
      removeTooltip();
    };

    const circles = rightSpacedEvents.reverse().map(d => (
      <circle
        key={d.id}
        cx={d.x}
        cy={dotRowTop + (tickHeight / 2)}
        fill="white"
        r={getCircleRadius(d)}
        className={getCircleClass(d)}
        onClick={() => {
          const eventWithSource = Object.assign({}, d, { eventSource: 'timeline' });
          setCurrentEvent(eventWithSource);
        }}
        onMouseMove={(e) => {
          const x = e.clientX;
          const y = e.clientY;

          setTooltip({ x, y, event: d });
        }}
        onMouseOver={() => mouseOver(d)}
        onFocus={() => mouseOver(d)}
        onMouseOut={mouseOut}
        onBlur={mouseOut}
      />
    ));
    return (
      <g>
        {ticks}
        {circles}
        <text
          x={labelsLeft}
          y={(dotRowTop * 3) - 5}
          className="timeline__events-label"
        >
          EVENTS
        </text>
      </g>
    );
  }

  getMaps({
    ticks,
    squares,
  }) {
    const {
      maps,
      width,
      labelsLeft,
    } = this.props;

    if (maps === null || width === null) return <div />;

    const svgHeight = 75;

    return (
      <g transform={`translate(0, ${svgHeight})`}>
        {ticks}
        {squares}
        <text
          x={labelsLeft}
          y="20"
          className="timeline__events-label"
        >
          Jump to:
        </text>
      </g>
    );
  }

  getConnectionLine({ x, y }) {
    const {
      width,
      highlightRectWidth,
    } = this.props;

    const linePadding = {
      top: 71,
      left: 15,
      right: 15,
    };
    const strokeWidth = 0.5;
    const breakStart = x + strokeWidth;
    const breakEnd = (x + highlightRectWidth) - strokeWidth;
    const breakDepth = linePadding.top + 10 + y;
    return (
      <g>
        <line
          x1={linePadding.left}
          x2={breakStart}
          y1={linePadding.top}
          y2={linePadding.top}
          className="timeline__line"
        />
        <line
          x1={breakEnd}
          x2={width - linePadding.right}
          y1={linePadding.top}
          y2={linePadding.top}
          className="timeline__line"
        />
        <line
          x1={breakStart}
          x2={breakStart}
          y1={linePadding.top}
          y2={breakDepth}
          className="timeline__line"
        />
        <line
          x1={breakEnd}
          x2={breakEnd}
          y1={linePadding.top}
          y2={breakDepth}
          className="timeline__line"
        />
      </g>
    );
  }

  getMapsData() {
    const {
      maps,
      setCurrentMap,
      currentMap,
      setTooltip,
      removeTooltip,
      width,
      padding,
      highlightRectWidth,
    } = this.props;

    const getMapYear = d => (d.yearStart === 0
      ? d.yearEnd
      : d.yearStart);

    const yearTicks = [];
    let increment = 108 / 3;
    for (let y = 1492; y <= 1850; y += increment) {
      yearTicks.push(y);
      if (y >= 1810) increment = 5;
      else if (y >= 1790) increment = 1;
      else if (y >= 1700) increment = 90 / 4;
      else if (y >= 1600) increment = 25;
    }

    const ticksScale = d3.scaleLinear()
      .domain([0, yearTicks.length - 1])
      .range([padding.left, width - padding.right]);

    const rowTop = 10;
    const tickHeight = 10;
    const getTickLabel = (year) => {
      if (year === 1492) return year;
      if (year % 100 === 0) return year;
      if (year >= 1790 && year % 10 === 0) return year;
      if (year >= 1790 && year <= 1810 && year % 5 === 0) return year;
      return '';
    };

    const ticks = yearTicks.map((d, i) => {
      const x = ticksScale(i);
      const translate = `translate(${Math.round(x)} ${rowTop})`;
      return (
        <g
          key={d}
          transform={translate}
        >
          <line
            x1="0"
            x2="0"
            y1="0"
            y2={tickHeight}
            className="timeline__tick"
          />
          <text
            textAnchor="middle"
            x="0"
            y={rowTop + 30}
          >
            {getTickLabel(d)}
          </text>
        </g>
      );
    });

    const isCurrentMap = d => currentMap !== null && currentMap.id === d.id;

    const getRectClass = (d) => {
      let className = 'timeline__map';
      if (isCurrentMap(d)) {
        className += ' timeline__map--selected';
      }
      return className;
    };

    const getRectWidth = (d) => {
      if (isCurrentMap(d)) return highlightRectWidth;
      return 10;
    };

    // group maps into arrays by nearest timeline tick
    const mapYears = maps.map((d) => {
      const year = getMapYear(d);
      let tickIndex = 0;
      // find the closest timeline tick for this year
      while (Math.abs(yearTicks[tickIndex + 1] - year) < Math.abs(yearTicks[tickIndex] - year)) {
        tickIndex += 1;
      }
      return Object.assign({ tickIndex }, d);
    })
      .sort((a, b) => a.tickIndex - b.tickIndex)
      .reduce((array, d) => {
        const arrayCopy = array.slice();
        const prevTick = arrayCopy.length ? arrayCopy[arrayCopy.length - 1][0].tickIndex : -1;
        if (d.tickIndex !== prevTick) {
          arrayCopy.push([d]);
        } else {
          arrayCopy[arrayCopy.length - 1].push(d);
        }
        return arrayCopy;
      }, []);

    let currentMapPos = null;
    const squares = [].concat(...mapYears.map(m => (
      m.map((d, i) => {
        const squareWidth = getRectWidth(d);
        // get vertical offsets knowing the size of the sub-array for this year
        const yOffset = ((m.length - 1) / 2 - i) * (squareWidth + 2);
        const squareTop = isCurrentMap(d)
          ? rowTop - 3
          : rowTop;
        const x = ticksScale(d.tickIndex) - (squareWidth / 2);
        const y = squareTop - yOffset;
        if (isCurrentMap(d)) {
          currentMapPos = { x, y };
        }
        return (
          <rect
            key={d.id}
            width={squareWidth}
            height={squareWidth}
            x={x}
            y={y}
            fill="white"
            className={getRectClass(d)}
            onClick={() => setCurrentMap(d)}
            onMouseMove={(e) => {
              setTooltip({ x: e.clientX, y: e.clientY, event: d });
            }}
            onMouseOut={() => { removeTooltip(); }}
            onBlur={() => { removeTooltip(); }}
          />
        );
      })
    )));

    return {
      squares,
      ticks,
      currentMapPos,
    };
  }

  getSVG() {
    const { width } = this.props;
    const svgStyle = {
      width: `${width}px`,
      height: '$138px',
    };
    const {
      ticks,
      squares,
      currentMapPos,
    } = this.getMapsData();
    // get map positions, etc.

    return (
      <svg style={svgStyle} className="timeline__svg">
        {this.getEvents()}
        {this.getConnectionLine(currentMapPos)}
        {this.getMaps({
          ticks,
          squares,
        })}
      </svg>
    );
  }

  render() {
    return (
      <div className="timeline__inner">
        <div className="timeline__content">
          {this.getSVG()}
        </div>
      </div>
    );
  }
}

TimelineChart.defaultProps = {
  currentEvent: null,
  currentMap: null,
  highlightEvent: null,
  width: null,
  padding: {
    top: 0,
    bottom: 0,
    left: 120,
    right: 30,
  },
  labelsLeft: 15,
  highlightRectWidth: 16,
};

TimelineChart.propTypes = {
  currentEvent: PropTypes.shape({
    actors: PropTypes.array,
    citation: PropTypes.string,
    date: PropTypes.object,
    displayDate: PropTypes.string,
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  highlightEvent: PropTypes.shape({
    actors: PropTypes.array,
    citation: PropTypes.string,
    date: PropTypes.object,
    displayDate: PropTypes.string,
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  currentMap: PropTypes.shape({
    actors: PropTypes.array,
    description: PropTypes.string,
    id: PropTypes.string,
    map: PropTypes.number,
    mapCaption: PropTypes.string,
    title: PropTypes.string,
    yearEnd: PropTypes.number,
    yearStart: PropTypes.number,
  }),
  setCurrentEvent: PropTypes.func.isRequired,
  setHighlightEvent: PropTypes.func.isRequired,
  setCurrentMap: PropTypes.func.isRequired,
  setTooltip: PropTypes.func.isRequired,
  removeTooltip: PropTypes.func.isRequired,
  width: PropTypes.number,
  maps: PropTypes.arrayOf(PropTypes.object).isRequired,
  padding: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
    bottom: PropTypes.number,
    right: PropTypes.number,
  }),
  labelsLeft: PropTypes.number,
  highlightRectWidth: PropTypes.number,
};

export default TimelineChart;
