import React from 'react';
import * as d3 from 'd3';

import Header from '../Header/Header';
import Atlas from '../Atlas/Atlas';
import Sidebar from '../Sidebar/Sidebar';
import Legend from '../Legend/Legend';
import Timeline from '../Timeline/Timeline';
import AtlasNavButtons from '../AtlasNavButtons/AtlasNavButtons';
import About from '../About/About';

import './App.scss';

const cleanActors = actors => actors
  .map(d => ({
    name: d.Name,
    // color: d.Color === undefined ? 'darkgrey' : d.Color,
    color: d.Color,
    polygon: d.Polygon,
    id: d.id,
  }));

const cleanEvent = ({ events, timeExtent }) => events
  .filter(d => d !== null)
  .map((d) => {
    const date = new Date(d.Date);
    const eventTime = date.getTime();

    const totalSpan = timeExtent[1] - timeExtent[0];

    const datePct = totalSpan === 0
      ? 0
      : ((eventTime - timeExtent[0]) / totalSpan) * 100;
    const zoomExtent = [6, 10];
    let anchorOffset = null;
    if (d.id === 'reczjJlpC7aHPelAI') {
      anchorOffset = {
        x: d3.scaleLinear().domain(zoomExtent).range([-10, -10]),
        y: d3.scaleLinear().domain(zoomExtent).range([-10, -10]),
      };
    }
    if (d.id === 'recyZ0rkW0ayjduMW') {
      anchorOffset = {
        x: d3.scaleLinear().domain(zoomExtent).range([5, 5]),
        y: d3.scaleLinear().domain(zoomExtent).range([5, 5]),
      };
    }
    if (d.id === 'recYs4JKYUpUO8lTp') {
      anchorOffset = {
        x: d3.scaleLinear().domain(zoomExtent).range([10, 10]),
        y: d3.scaleLinear().domain(zoomExtent).range([0, 0]),
      };
    }
    // const anchorOffset = {
    //   x: d3.scaleLinear().domain(zoomExtent).range([0, 100]),
    //   y: d3.scaleLinear().domain(zoomExtent).range([0, 300]),
    // };

    return {
      actors: d.Actors === undefined || d.Actors === null ? [] : cleanActors(d.Actors),
      citation: d.Citation,
      date,
      description: d.Description,
      displayDate: d['Display Date'],
      datePct,
      lat: d.Latitude,
      lng: d.Longitude,
      name: d.Name,
      type: d.Type,
      id: d.id,
      anchorOffset,
    };
  }).sort((a, b) => a.date - b.date);

const cleanMaps = rawData => rawData.map((d) => {
  let timeExtent = null;
  if (d.Events && d.Events.length > 0) {
    timeExtent = d3.extent(d.Events, dd => new Date(dd.Date).getTime());
  }
  return {
    actors: d.Actors === undefined ? [] : cleanActors(d.Actors),
    description: d.Description,
    order: d.Order,
    map: d.Map,
    mapCaption: d['Map Caption'],
    title: d.Title,
    yearEnd: d['Year End'],
    yearStart: d['Year Start'],
    id: d.id,
    events: d.Events === undefined || d.Events === null
      ? []
      : cleanEvent({ events: d.Events, timeExtent }),
  };
}).sort((a, b) => a.order - b.order);

const cleanPolygons = rawPolygons => rawPolygons.features;

const getEventWithSource = event => Object.assign(
  {},
  event,
  { eventSource: 'autoPlay' },
);

class App extends React.Component {
  constructor(props) {
    super(props);

    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    this.state = {
      dataLoaded: false,
      currentMap: null,
      currentEvent: null,
      highlightEvent: null,
      width: window.innerWidth,
      height: window.innerHeight,
      autoPlayOn: false,
      aboutOn: false,
      sidebarOpen: true,
      mobile,
      mobileSidebarView: 'collapsed',
      legendOpen: false,
      dropdownOpen: false,
    };


    this.atlasRef = React.createRef();
    this.autoPlayInterval = null;

    this.setCurrentMap = this.setCurrentMap.bind(this);
    this.setCurrentEvent = this.setCurrentEvent.bind(this);
    this.setHighlightEvent = this.setHighlightEvent.bind(this);
    this.setDimensions = this.setDimensions.bind(this);
    this.stepNextMap = this.stepNextMap.bind(this);
    this.stepPrevMap = this.stepPrevMap.bind(this);
    this.toggleAutoPlay = this.toggleAutoPlay.bind(this);
    this.stepNextEvent = this.stepNextEvent.bind(this);
    this.stepPrevEvent = this.stepPrevEvent.bind(this);
    this.closeAbout = this.closeAbout.bind(this);
    this.openAbout = this.openAbout.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    this.setMobileSidebarView = this.setMobileSidebarView.bind(this);
    this.openLegend = this.openLegend.bind(this);
    this.closeLegend = this.closeLegend.bind(this);
    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    // this.counter = 0;
  }

  componentDidMount() {
    this.loadData();
    this.setDimensions();
    this.listenForResize();
  }


  setCurrentMap(newCurrentMap) {
    const { currentMap } = this.state;
    if (newCurrentMap.id === currentMap.id) return;

    this.setState({
      currentEvent: null,
      currentMap: newCurrentMap,
    });
  }

  setCurrentEvent(newEvent) {
    const { currentEvent } = this.state;
    if (currentEvent === null || currentEvent.id !== newEvent.id) {
      this.setState({
        currentEvent: newEvent,
      });
    } else {
      this.setState({
        currentEvent: null,
      });
    }
  }

  setHighlightEvent(newEvent) {
    this.setState({
      highlightEvent: newEvent,
    });
  }

  setDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  setMobileSidebarView(newView) {
    this.setState({
      mobileSidebarView: newView,
    });
  }

  getLegendItems() {
    const {
      currentMap,
    } = this.state;
    const { actors, events } = currentMap;

    const eventTypes = [...new Set(events.map(d => d.type))]
      .map(d => (d === undefined ? 'Other' : d));
    return { actors, eventTypes };
  }

  getAbout() {
    const {
      aboutOn,
      mobile,
    } = this.state;
    if (!aboutOn) return <div />;
    return (
      <About
        mobile={mobile}
        close={this.closeAbout}
      />
    );
  }

  getLegend() {
    const {
      currentMap,
      mobile,
      legendOpen,
    } = this.state;
    if (!currentMap.events) return <div />;
    if (mobile && !legendOpen) return <div />;
    const {
      actors,
      eventTypes,
    } = this.getLegendItems();

    return (
      <Legend
        mobile={mobile}
        currentMap={currentMap}
        actors={actors}
        eventTypes={eventTypes}
      />
    );
  }


  getNextMap() {
    const { currentMap } = this.state;
    const { maps } = this;

    const pos = maps.map(d => d.map)
      .indexOf(currentMap.map);

    if (pos === maps.length - 1) {
      return maps[0];
    }

    return maps[pos + 1];
  }

  getPrevMap() {
    const { currentMap } = this.state;
    const { maps } = this;

    const pos = maps.map(d => d.map)
      .indexOf(currentMap.map);

    if (pos === 0) {
      return maps[maps.length - 1];
    }

    return maps[pos - 1];
  }


  getSidebarDesktop() {
    const {
      sidebarOpen,
      currentMap,
      currentEvent,
      mobile,
    } = this.state;
    if (mobile) return <div />;
    return (
      <Sidebar
        sidebarOpen={sidebarOpen}
        currentMap={currentMap}
        setCurrentEvent={this.setCurrentEvent}
        toggleSidebar={this.toggleSidebar}
        currentEvent={currentEvent}
        setHighlightEvent={this.setHighlightEvent}
        mobile={mobile}
      />
    );
  }

  getSidebarMobile() {
    const {
      sidebarOpen,
      currentMap,
      currentEvent,
      mobile,
      mobileSidebarView,
      dropdownOpen,
    } = this.state;
    if (!mobile) return <div />;
    return (
      <Sidebar
        dropdownOpen={dropdownOpen}
        sidebarOpen={sidebarOpen}
        currentMap={currentMap}
        setCurrentEvent={this.setCurrentEvent}
        toggleSidebar={this.toggleSidebar}
        currentEvent={currentEvent}
        setHighlightEvent={this.setHighlightEvent}
        setMobileSidebarView={this.setMobileSidebarView}
        mobile={mobile}
        mobileSidebarView={mobileSidebarView}
      />
    );
  }

  getTimeline() {
    const {
      width,
      autoPlayOn,
      sidebarOpen,
      currentMap,
      currentEvent,
      mobile,
      highlightEvent,
    } = this.state;
    // if (mobile) return null;
    return (
      <Timeline
        sidebarOpen={sidebarOpen} // just to trigger re-render
        maps={this.maps}
        currentMap={currentMap}
        currentEvent={currentEvent}
        width={width}
        setCurrentEvent={this.setCurrentEvent}
        setCurrentMap={this.setCurrentMap}
        atlasRef={this.atlasRef}
        autoPlayOn={autoPlayOn}
        toggleAutoPlay={this.toggleAutoPlay}
        setHighlightEvent={this.setHighlightEvent}
        highlightEvent={highlightEvent}
        mobile={mobile}
      />
    );
  }

  stepPrevEvent() {
    const { currentEvent, currentMap } = this.state;
    const { events } = currentMap;

    const goToPrevMap = () => {
      const nextMap = this.getPrevMap();
      if (nextMap.events.length === 0) {
        // this.stepPrevMap();
        // // prevent infinite loop
        // setTimeout(() => {
        //   goToPrevMap();
        // });
        this.setState({
          currentMap: nextMap,
          currentEvent: null,
        });
      } else {
        this.setState({
          currentMap: nextMap,
          currentEvent: nextMap.events[nextMap.events.length - 1],
        });
      }
    };

    if (events.length === 0) {
      goToPrevMap();
    } else if (currentEvent === null) {
      goToPrevMap();
    } else {
      const currentIndex = events
        .map(d => d.id)
        .indexOf(currentEvent.id);

      if (currentIndex === 0) {
        goToPrevMap();
      } else {
        this.setState({
          currentEvent: events[currentIndex - 1],
        });
      }
    }
  }

  stepNextEvent() {
    const { currentEvent, currentMap } = this.state;
    const { events } = currentMap;


    const goToNextMap = () => {
      const nextMap = this.getNextMap();

      // if (nextMap.events.length === 0) {
      //   this.stepNextMap();
      //   // prevent infinite loop
      //   setTimeout(() => {
      //     goToNextMap();
      //   });
      // } else {
      //   this.setState({
      //     currentMap: nextMap,
      //     currentEvent: getEventWithSource(nextMap.events[0]),
      //   });
      // }
      this.setState({
        currentMap: nextMap,
        currentEvent: null,
      });
    };

    if (events.length === 0) {
      goToNextMap();
    } else if (currentEvent === null) {
      this.setState({
        currentEvent: getEventWithSource(events[0]),
      });
    } else {
      const currentIndex = events
        .map(d => d.id)
        .indexOf(currentEvent.id);

      if (currentIndex === events.length - 1) {
        goToNextMap();
      } else {
        this.setState({
          currentEvent: getEventWithSource(events[currentIndex + 1]),
        });
      }
    }
  }

  beginAutoPlay() {
    this.autoPlayInterval = setInterval(() => {
      this.stepNextEvent();
    }, 3000);
  }

  stepNextMap() {
    this.setState({
      currentMap: this.getNextMap(),
    });
  }

  stepPrevMap() {
    const { currentMap } = this.state;
    const { maps } = this;
    const pos = maps.map(d => d.map)
      .indexOf(currentMap.map);
    if (pos === 0) {
      this.setState({
        currentEvent: null,
        currentMap: maps[maps.length - 1],
      });
    } else {
      this.setState({
        currentEvent: null,
        currentMap: getEventWithSource(maps[pos - 1]),
      });
    }
  }

  toggleAutoPlay() {
    const { autoPlayOn } = this.state;
    const newAutoPlayStatus = !autoPlayOn;
    if (newAutoPlayStatus) {
      this.beginAutoPlay();
    } else {
      this.clearAutoPlay();
    }
    this.setState({
      autoPlayOn: !autoPlayOn,
    });
  }

  listenForResize() {
    window.addEventListener('resize', () => {
      this.setDimensions();
    });
  }

  async loadData() {
    const [rawMaps, rawPolygons] = await Promise.all([
      d3.json('data/data.json'),
      d3.json('data/polygons.geojson'),
    ]);

    this.maps = cleanMaps(rawMaps);
    this.polygons = cleanPolygons(rawPolygons);

    this.setState({
      currentMap: this.maps[0],
      dataLoaded: true,
    });
  }

  toggleSidebar() {
    const { sidebarOpen } = this.state;
    this.setState({
      sidebarOpen: !sidebarOpen,
    });
  }

  clearAutoPlay() {
    if (this.autoPlayInterval === null) return;
    clearTimeout(this.autoPlayInterval);
    this.autoPlayInterval = null;
  }

  openAbout() {
    this.setState({
      aboutOn: true,
    });
  }

  openLegend() {
    this.setState({
      legendOpen: true,
    });
  }

  closeLegend() {
    this.setState({
      legendOpen: false,
    });
  }

  openDropdown() {
    this.setState({
      dropdownOpen: true,
    });
  }

  closeDropdown() {
    this.setState({
      dropdownOpen: false,
    });
  }

  closeAbout() {
    this.setState({
      aboutOn: false,
    });
  }

  render() {
    const {
      currentMap,
      // width,
      dataLoaded,
      currentEvent,
      // autoPlayOn,
      // mobile,
      sidebarOpen,
      highlightEvent,
      mobile,
      legendOpen,
      dropdownOpen,
      mobileSidebarView,
    } = this.state;
    const { maps, polygons } = this;
    if (!dataLoaded) return <div />;

    let containerClass = 'app';
    if (mobile) {
      containerClass += ' app--mobile';
    }
    let atlasContainerClass = 'atlas-outer';
    if (mobile) {
      atlasContainerClass += ` ${atlasContainerClass}--mobile`;
    }

    return (
      <div className={containerClass}>
        <Header
          currentMap={currentMap}
          setCurrentMap={this.setCurrentMap}
          maps={maps}
          openAbout={this.openAbout}
          mobile={mobile}
          openLegend={this.openLegend}
          closeLegend={this.closeLegend}
          openDropdown={this.openDropdown}
          closeDropdown={this.closeDropdown}
          dropdownOpen={dropdownOpen}
          legendOpen={legendOpen}
          mobileSidebarView={mobileSidebarView}
        >
          <AtlasNavButtons
            stepNextMap={this.stepNextEvent}
            stepPrevMap={this.stepPrevEvent}
            sidebarOpen={sidebarOpen}
            mobile={mobile}
          />
        </Header>
        <div className="app__content">
          {this.getSidebarDesktop()}
          <div className="app__atlas-content">
            {this.getLegend()}
            <div className={atlasContainerClass}>
              <Atlas
                currentMap={currentMap}
                currentEvent={currentEvent}
                polygons={polygons}
                setCurrentEvent={this.setCurrentEvent}
                atlasRef={this.atlasRef}
                sidebarOpen={sidebarOpen}
                setHighlightEvent={this.setHighlightEvent}
                highlightEvent={highlightEvent}
                mobile={mobile}
              />
              {mobile && (
                <AtlasNavButtons
                  stepNextMap={this.stepNextEvent}
                  stepPrevMap={this.stepPrevEvent}
                  sidebarOpen={sidebarOpen}
                  mobile={mobile}
                />
              )}
            </div>
            {this.getSidebarMobile()}
            {this.getTimeline()}
          </div>
        </div>
        {this.getAbout()}
      </div>
    );
  }
}

export default App;
