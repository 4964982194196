import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay, faPause,
} from '@fortawesome/pro-solid-svg-icons';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './TimelinePlayButton.scss';

class TimelinePlayButton extends React.PureComponent {
  render() {
    const {
      toggleAutoPlay,
      autoPlayOn,
      currentEvent,
      mobile,
    } = this.props;
    let value = 0;
    if (currentEvent !== null) {
      value = currentEvent.datePct;
    }
    let containerClass = 'play';
    if (mobile) {
      containerClass += ` ${containerClass}--mobile`;
    }
    return (
      <div
        className={containerClass}
        onClick={toggleAutoPlay}
      >
        <CircularProgressbarWithChildren
          value={value}
          background
          strokeWidth={10}
          styles={buildStyles({
            backgroundColor: 'white',
            trailColor: 'white',
            strokeLinecap: 'butt',
          })}
          
        >
          <FontAwesomeIcon
            icon={autoPlayOn ? faPause : faPlay}
          />
        </CircularProgressbarWithChildren>

      </div>
    );
  }
}

TimelinePlayButton.defaultProps = {
  currentEvent: null,
};

TimelinePlayButton.propTypes = {
  toggleAutoPlay: PropTypes.func.isRequired,
  autoPlayOn: PropTypes.bool.isRequired,
  currentEvent: PropTypes.shape({
    actors: PropTypes.array,
    citation: PropTypes.string,
    date: PropTypes.object,
    displayDate: PropTypes.string,
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  mobile: PropTypes.bool.isRequired,
};

export default TimelinePlayButton;
