import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/pro-solid-svg-icons';
import './AtlasNavButtons.scss';

class AtlasNavButtons extends React.PureComponent {
  render() {
    const {
      stepNextMap,
      stepPrevMap,
      sidebarOpen,
      mobile,
    } = this.props;
    const getContainerClass = () => {
      let base = 'atlas-nav';
      if (!sidebarOpen) {
        base += ' atlas-nav--sidebar-collapsed';
      }
      if (mobile) {
        base += ' atlas-nav--mobile';
      }
      return base;
    };
    return (
      <div className={getContainerClass()}>
        <div
          className="atlas-nav__button atlas-nav__prev"
          onClick={stepPrevMap}
        >
          <FontAwesomeIcon className="atlas-nav__icon atlas-nav__icon-left" icon={faChevronLeft} />
          Prev
        </div>
        <div
          className="atlas-nav__button atlas-nav__next"
          onClick={stepNextMap}
        >
          Next
          <FontAwesomeIcon className="atlas-nav__icon atlas-nav__icon-right" icon={faChevronRight} />
        </div>
      </div>
    );
  }
}

AtlasNavButtons.propTypes = {
  stepNextMap: PropTypes.func.isRequired,
  stepPrevMap: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
};

export default AtlasNavButtons;
