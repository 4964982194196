import React from 'react';
import * as L from 'leaflet';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import Tooltip from '../Tooltip/Tooltip';
import './Atlas.scss';
import 'leaflet/dist/leaflet.css';

const icons = new Map();
icons.set('Settlement', 'users');
icons.set('Colonial Settlement', 'users');
icons.set('Battle', 'swords');
icons.set('Revolt', 'fire-alt');
icons.set('Changing Alliance', 'handshake-alt');
icons.set('Evacuation', 'running');

const getIcon = (event) => {
  const icon = icons.get(event.type);

  if (icon === undefined) return 'map-marker';
  return icon;
};

const isCurrentEvent = ({
  event,
  currentEvent,
}) => currentEvent !== null
&& currentEvent.id === event.id;

const isHighlightEvent = ({ event, highlightEvent }) => highlightEvent !== null
&& highlightEvent.id === event.id;

// NW, SE
const homeBounds = L.latLngBounds(L.latLng(20.102956, -74.455806), L.latLng(17.713875, -68.4539));

const homeCenter = [18.996443, -72.297406];
const homeZoom = 8;

const getEventClass = ({
  event,
  currentEvent,
}) => {
  let className = 'atlas__div-icon-content';
  if (isCurrentEvent({
    event,
    currentEvent,
  })) {
    className += ' atlas__div-icon-content--selected';
  }
  return className;
};

const getColor = icon => (icon.color
  ? icon.color.toLowerCase()
  : 'darkgrey');

// const getEventHTML = ({
//   event,
//   currentEvent,
//   // highlightEvent,
// }) => {
//   const content = event.actors.reduce((accumulator, d) => `
//   ${accumulator} <div class="atlas__icon" style="color:${getColor(d)};">
//     <i class="fas fa-${getIcon(event)}"></i>
//     </div>
//   `,
//   '');

//   return `
//     <div class="${getEventClass({ event, currentEvent })}">
//       ${content}
//     </div>
//   `;
// };

class Atlas extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: null,
    };
    this.loggedData = {};
  }

  componentDidMount() {
    this.logData();
    this.logSidebar();
    this.logHighlight();
    this.createMap();
    this.addHomeButton();
    this.updateData();
    // this.setHomeButtonStatus();
  }

  componentDidUpdate() {
    const shouldUpdateData = this.dataIsNew();
    const shouldResize = this.sidebarChanged();
    const changeHighlight = this.highlightIsNew();

    if (shouldUpdateData) {
      this.logData();
      this.updateData();
    }

    if (shouldResize) {
      this.logSidebar();
      this.forceResize();
    }

    if (changeHighlight) {
      this.logHighlight();
      this.setHighlight();
    }
  }

  setPolygonTooltip({
    x,
    y,
    event,
  }) {
    const { currentMap } = this.props;

    const getRow = () => {
      if (event.name === null) {
        const actor = currentMap.actors.find(d => d.polygon === event.actor);
        if (typeof actor === 'object') {
          return (
            <div className="tooltip__row">
              <div className="tooltip__field">Actor: </div>
              <div className="tooltip__text" dangerouslySetInnerHTML={{ __html: actor.name }} />
            </div>
          );
        }
        return null;
      }
      return (
        <div className="tooltip__row">
          <div className="tooltip__field">Name: </div>
          <div className="tooltip__text" dangerouslySetInnerHTML={{ __html: event.name }} />
        </div>
      );
    };
    const row = getRow();
    if (row === null) return;
    const tooltip = (
      <Tooltip
        x={x}
        y={y}
      >
        {row}
      </Tooltip>
    );
    this.setState({
      tooltip,
    });
  }

  setTooltip({
    x,
    y,
    event,
  }) {
    const getActorsRow = () => {
      if (!event.actors || event.actors.length === 0) return <div />;
      /* eslint-disable no-param-reassign */
      const actors = event.actors.reduce((accumulator, actor, i) => {
        if (event.actors.length !== 1 && i !== 0) {
          accumulator += ', ';
        }
        accumulator += actor.name;
        return accumulator;
      }, '');
      /* eslint-enable no-param-reassign */
      return (
        <div className="tooltip__row">
          <div className="tooltip__field">Actors: </div>
          <div className="tooltip__text" dangerouslySetInnerHTML={{ __html: actors }} />
        </div>
      );
    };
    const tooltip = (
      <Tooltip
        x={x}
        y={y}
      >
        <div className="tooltip__row">
          <div className="tooltip__title" dangerouslySetInnerHTML={{ __html: event.name }} />
        </div>
      </Tooltip>
    );
    this.setState({
      tooltip,
    });
  }

  setHomeButtonStatus() {
    const center = this.leafletMap.getCenter();
    const zoom = this.leafletMap.getZoom();

    if (zoom === homeZoom
        && Math.abs(center.lat - homeCenter[0]) < 0.01
        && Math.abs(center.lng - homeCenter[1]) < 0.01) {
      this.homeButton.classList.add('atlas__home-button--deactivated');
    } else {
      this.homeButton.classList.remove('atlas__home-button--deactivated');
    }
  }

  setHighlight() {
    const { highlightEvent, iconWidth } = this.props;

    this.eventsLayer.eachLayer((layer) => {
      const { event } = layer.options;
      const el = layer.getElement();


      if (isHighlightEvent({ event, highlightEvent })) {
        layer._bringToFront();
        el.classList.add('atlas__div-icon-outer--highlighted');
      } else {
        el.classList.remove('atlas__div-icon-outer--highlighted');
      }
    });
  }


  getEventIcon({ event }) {
    const {
      currentEvent,
      // highlightEvent,
      iconWidth,
    } = this.props;
    const zoom = this.leafletMap.getZoom();
    const { anchorOffset } = event;
    const anchorDefault = [iconWidth / 2, iconWidth / 2];

    let anchor;

    if (anchorOffset !== null) {
      anchor = [
        anchorDefault[0] - anchorOffset.x(zoom),
        anchorDefault[1] - anchorOffset.y(zoom),
      ];
    } else {
      anchor = anchorDefault;
    }
    let classBase = 'atlas__div-icon-outer';
    if (isCurrentEvent({
      event,
      currentEvent,
    })) {
      classBase += ` ${classBase}--selected`;
    }

    const options = {
      className: classBase,
      iconSize: [iconWidth, iconWidth],
      iconAnchor: anchor,
      // html: getEventHTML({
      //   event,
      //   currentEvent,
      //   highlightEvent,
      // }),
    };
    return L.divIcon(options);
  }

  drawEventPie({ event, el }) {
    const { iconWidth } = this.props;
    const strokeWidth = 4;

    const radius = (iconWidth / 2) - strokeWidth;
    const thickness = 10;
    const { actors } = event;

    const svg = d3.select(el)
      .append('svg')
      .attr('width', iconWidth)
      .attr('height', iconWidth)
      .attr('class', 'atlas__event-svg');
    svg.append('circle')
      .attr('fill', 'white')
      .attr('class', 'atlas__event-circle')
      .attr('cx', iconWidth / 2)
      .attr('cy', iconWidth / 2)
      .attr('r', radius);
    svg.append('circle')
      .attr('fill', 'white')
      .attr('cx', iconWidth / 2)
      .attr('cy', iconWidth / 2)
      .attr('r', radius);

    d3.select(el)
      .append('div')
      .attr('class', 'atlas__event-icon')
      .html(`<i class="fas fa-${getIcon(event)}"></i>`);

    if (actors.length === 0) return;

    const val = 1;
    const arcs = d3.arc()
      .innerRadius(radius - thickness)
      .outerRadius(radius);

    const pieData = d3.pie()
      .value(val)(actors);

    svg
      .append('g')
      .attr('class', 'atlas__event-icon-group')
      .attr('transform', `translate(${strokeWidth}, ${strokeWidth})`)
      .selectAll('path')
      .data(pieData)
      .enter()
      .append('path')
      .attr('d', arcs)
      .attr('transform', `translate(${radius},${radius})`)
      .attr('fill', d => d.data.color);
  }

  logSidebar() {
    const { sidebarOpen } = this.props;
    Object.assign(this.loggedData, { sidebarOpen });
  }

  logData() {
    const { currentMap, currentEvent } = this.props;
    Object.assign(this.loggedData, { currentMap, currentEvent });
  }

  logHighlight() {
    const { highlightEvent } = this.props;
    Object.assign(this.loggedData, { highlightEvent });
  }

  highlightIsNew() {
    const {
      highlightEvent,
    } = this.props;
    const logged = this.loggedData.highlightEvent;

    const formatForComparison = event => (!event
      ? null
      : event.id);
    return formatForComparison(highlightEvent) !== formatForComparison(logged);
  }


  sidebarChanged() {
    const { sidebarOpen } = this.props;
    return sidebarOpen !== this.loggedData.sidebarOpen;
  }

  forceResize() {
    this.leafletMap.invalidateSize();
  }


  dataIsNew() {
    const {
      currentMap,
      currentEvent,
    } = this.props;

    // need to check if events are same by id, not entire object
    return (currentMap !== this.loggedData.currentMap
      || currentEvent !== this.loggedData.currentEvent);
  }

  removeTooltip() {
    this.setState({
      tooltip: null,
    });
  }

  createMap() {
    const { atlasRef, mobile } = this.props;

    this.leafletMap = new L.Map(atlasRef.current, {
      maxZoom: 10,
      minZoom: 6,
      maxBounds: homeBounds,
      zoomControl: false,
    })
      .fitBounds(homeBounds);
    // .setView(homeCenter, homeZoom);


    this.leafletMap.on('zoomend', () => {
      // this.eventsLayer.eachLayer((layer) => {
      //   const { event } = layer.options;
      //   if (event.anchorOffset !== null) {
      //     const newIcon = this.getEventIcon({ event });
      //     layer.setIcon(newIcon);
      //   }
      // });
    });
    // .on('moveend', () => {
    //   this.setHomeButtonStatus();
    // });
    if (mobile === false) {
      L.control.zoom({
        position: 'topright',
      })
        .addTo(this.leafletMap);
    }

    this.polygonsLayer = L.featureGroup()
      .addTo(this.leafletMap);

    this.eventsLayer = L.featureGroup()
      .addTo(this.leafletMap);

    L.tileLayer('data/tiles/{z}/{x}/{y}.png', { tms: true }).addTo(this.leafletMap);
  }

  addHomeButton() {
    const button = document.createElement('div');

    button.innerHTML = '<i class="fas fa-home"></i>';

    button.addEventListener('click', () => {
      this.leafletMap.fitBounds(homeBounds);
    });

    button.classList.add('atlas__home-button');

    document
      .getElementsByClassName('atlas-outer')[0]
      .appendChild(button);
    this.homeButton = button;
  }

  drawPolygons() {
    const {
      currentMap,
      mobile,
      polygons,
    } = this.props;
    if (!currentMap || !polygons) return;
    this.polygonsLayer.clearLayers();
    const currentPolygons = polygons
      .filter(d => d.properties.map === currentMap.map);

    if (currentPolygons.length === 0) return;
    L.geoJSON(currentPolygons, {
      style(feature) {
        let color;
        const actor = currentMap.actors.find(d => d.polygon === feature.properties.actor);
        if (feature.properties.color !== null) {
          ({ color } = feature.properties);
        } else if (actor !== undefined) {
          ({ color } = actor);
        }
        if (!color) {
          color = 'lightgrey';
        }
        return {
          className: 'atlas__polygon',
          color,
        };
      },
    })
      .on('mousemove', (e) => {
        if (mobile) return;
        const x = e.originalEvent.clientX;
        const y = e.originalEvent.clientY;
        const event = e.layer.feature.properties;

        this.setPolygonTooltip({
          x,
          y,
          event,
        });
      })
      .on('mouseout', () => {
        if (mobile) return;
        this.removeTooltip();
      })
      .on('click', (e) => {
        const x = e.originalEvent.clientX;
        const y = e.originalEvent.clientY;
        const event = e.layer.feature.properties;

        this.setPolygonTooltip({
          x,
          y,
          event,
        });
        const removeProbe = () => {
          document.body.removeEventListener('mousedown', removeProbe);
          this.removeTooltip();
        };
        e.originalEvent.stopPropagation();
        document.body.addEventListener('mousedown', removeProbe);
      })
      .addTo(this.polygonsLayer);
  }


  drawMarkers() {
    const {
      currentMap,
      setCurrentEvent,
      currentEvent,
      setHighlightEvent,
      mobile,
    } = this.props;
    this.eventsLayer.clearLayers();

    if (currentMap === null || currentMap.events === null) return;

    currentMap.events.forEach((event) => {
      if (!event) return;
      if (!event.lat
        || !event.lng
        || !event.actors) return;

      const divIcon = this.getEventIcon({ event });

      const marker = L.marker([event.lat, event.lng], {
        icon: divIcon,
        event,
        riseOnHover: true,
      });

      marker.on('click', () => {
        const eventWithSource = Object.assign(
          {},
          event,
          { eventSource: 'atlas' },
        );
        setCurrentEvent(eventWithSource);
      })
        .on('mouseover', () => {
          if (mobile) return;
          setHighlightEvent(event);
        })
        .on('mousemove', (e) => {
          if (mobile) return;
          const x = e.originalEvent.clientX;
          const y = e.originalEvent.clientY;

          this.setTooltip({ x, y, event });
        })
        .on('mouseout', () => {
          if (mobile) return;
          this.removeTooltip();
          setHighlightEvent(null);
        });
      marker.addTo(this.eventsLayer);
    });
    this.eventsLayer.eachLayer((layer) => {
      const { event } = layer.options;
      const el = layer.getElement();

      this.drawEventPie({
        event,
        el,
      });
      if (isCurrentEvent({
        event,
        currentEvent,
      })) {
        layer._bringToFront();
      }
      // DRAW PIE HERE
    });
    if (currentEvent !== null
      && currentEvent.eventSource !== 'atlas') {
      this.leafletMap.panTo(L.latLng(currentEvent.lat, currentEvent.lng));
    }
  }

  updateData() {
    this.drawMarkers();
    this.drawPolygons();
  }

  render() {
    const {
      atlasRef,
      // mobile,
    } = this.props;
    const { tooltip } = this.state;
    const containerClass = 'atlas';
    
    return (
      <div
        className={containerClass}
        ref={atlasRef}
      >
        {tooltip}
      </div>
    );
  }
}

Atlas.defaultProps = {
  currentMap: null,
  currentEvent: null,
  polygons: null,
  highlightEvent: null,
  iconWidth: 50,
};

Atlas.propTypes = {
  atlasRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  currentMap: PropTypes.shape({
    actors: PropTypes.array,
    description: PropTypes.string,
    id: PropTypes.string,
    map: PropTypes.number,
    mapCaption: PropTypes.string,
    title: PropTypes.string,
    yearEnd: PropTypes.number,
    yearStart: PropTypes.number,
  }),
  currentEvent: PropTypes.shape({
    actors: PropTypes.array,
    citation: PropTypes.string,
    date: PropTypes.object,
    displayDate: PropTypes.string,
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  highlightEvent: PropTypes.shape({
    actors: PropTypes.array,
    citation: PropTypes.string,
    date: PropTypes.object,
    displayDate: PropTypes.string,
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  setHighlightEvent: PropTypes.func.isRequired,
  setCurrentEvent: PropTypes.func.isRequired,
  polygons: PropTypes.arrayOf(PropTypes.object),
  mobile: PropTypes.bool.isRequired,
  iconWidth: PropTypes.number,
};

export default Atlas;
