import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip/Tooltip';
import TimelineChart from './TimelineChart';
import TimelinePlayButton from '../TimelinePlayButton/TimelinePlayButton';
import './Timeline.scss';

class Timeline extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: null,
      height: null,
      tooltip: null,
    };
    this.setSize = this.setSize.bind(this);
    this.setTooltip = this.setTooltip.bind(this);
    this.removeTooltip = this.removeTooltip.bind(this);
  }

  componentDidMount() {
    this.setSize();
  }

  componentDidUpdate() {
    this.setSize();
  }

  getTimelineChart() {
    const {
      currentMap,
      setCurrentEvent,
      setHighlightEvent,
      maps,
      setCurrentMap,
      mobile,
      highlightEvent,
      currentEvent,
    } = this.props;
    
    if (mobile) return null;

    const { width } = this.state;
    return (
      <TimelineChart
        currentMap={currentMap}
        setCurrentEvent={setCurrentEvent}
        currentEvent={currentEvent}
        setHighlightEvent={setHighlightEvent}
        setCurrentMap={setCurrentMap}
        maps={maps}
        setTooltip={this.setTooltip}
        removeTooltip={this.removeTooltip}
        width={width}
        highlightEvent={highlightEvent}
      />
    );
  }

  setSize() {
    const { atlasRef } = this.props;
    if (!atlasRef.current) return;
    const {
      width,
      height,
    } = atlasRef.current.getBoundingClientRect();

    this.setState({
      width,
      height,
    });
  }

  setTooltip({
    x,
    y,
    event,
  }) {
    const tooltip = (
      <Tooltip x={x} y={y}>
        <div className="tooltip__row">
          <div className="tooltip__field">{event.displayDate || `${event.yearStart} – ${event.yearEnd}`}</div>
        </div>
        <div className="tooltip__row">
          <div className="tooltip__prompt">Click to learn more...</div>
        </div>
      </Tooltip>
    );
    this.setState({
      tooltip,
    });
  }

  removeTooltip() {
    this.setState({
      tooltip: null,
    });
  }

  render() {
    const { tooltip } = this.state;
    const {
      autoPlayOn,
      toggleAutoPlay,
      currentEvent,
      mobile,
    } = this.props;

    let containerClass = 'timeline';
    if (mobile) {
      containerClass += ` ${containerClass}--mobile`;
    }

    return (
      <div className={containerClass}>
        {this.getTimelineChart()}
        {tooltip}
        <TimelinePlayButton
          mobile={mobile}
          autoPlayOn={autoPlayOn}
          toggleAutoPlay={toggleAutoPlay}
          currentEvent={currentEvent}
        />
      </div>
    );
  }
}

Timeline.defaultProps = {
  currentEvent: null,
  currentMap: null,
  highlightEvent: null,
};

Timeline.propTypes = {
  maps: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentEvent: PropTypes.shape({
    actors: PropTypes.array,
    citation: PropTypes.string,
    date: PropTypes.object,
    displayDate: PropTypes.string,
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  currentMap: PropTypes.shape({
    actors: PropTypes.array,
    description: PropTypes.string,
    id: PropTypes.string,
    map: PropTypes.number,
    mapCaption: PropTypes.string,
    title: PropTypes.string,
    yearEnd: PropTypes.number,
    yearStart: PropTypes.number,
  }),
  highlightEvent: PropTypes.shape({
    actors: PropTypes.array,
    citation: PropTypes.string,
    date: PropTypes.object,
    displayDate: PropTypes.string,
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  atlasRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  setCurrentEvent: PropTypes.func.isRequired,
  setHighlightEvent: PropTypes.func.isRequired,
  toggleAutoPlay: PropTypes.func.isRequired,
  autoPlayOn: PropTypes.bool.isRequired,
  setCurrentMap: PropTypes.func.isRequired,
  mobile: PropTypes.bool.isRequired,
};

export default Timeline;
