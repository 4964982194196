import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import './Tooltip.scss';

const tooltipRoot = document.getElementById('tooltip-root');

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    tooltipRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    tooltipRoot.removeChild(this.el);
  }

  render() {
    const {
      x,
      y,
      children,
    } = this.props;
    const style = {};
    if (x > window.innerWidth / 2) style.right = `${window.innerWidth - x}px`;
    else style.left = `${x}px`;
    if (y > 200) style.bottom = `${window.innerHeight - y}px`;
    else style.top = `${y}px`;
    const tooltip = (
      <div className="tooltip" style={style}>
        <div className="tooltip__inner">
          {children}
        </div>
      </div>
    );

    return ReactDOM.createPortal(
      tooltip,
      this.el,
    );
  }
}

Tooltip.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  // children: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]).isRequired,
};

export default Tooltip;
